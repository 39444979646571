<template>
	<div class="product-layout" :class="[classes(product.classes), cssClass]">
	    <div class="product-thumb">
	        <div class="name" v-if="getIn(prefix + 'NamePosition') == 'image'">
	        	<a :href="product.href">{{ product.name }}</a>
	        </div>
	      	<div class="image">
				<div class="resealed_product_c" v-if="product.is_resealed != 0">
					<p class="c_resealed">Resigilat</p>
				</div>
				<div class="free_delivery_product_c" v-if="product.free_delivery != 0">
					<p class="c_free_delivery">Transport gratuit</p>
				</div>
		        <div class="quickview-button" v-if="getSetting('quickviewStatus')">
        			<a class="btn btn-quickview"
	            		:data-toggle="quickViewContext.toggle"
	            		:data-tooltip-class="[quickViewContext.tooltipClass, 'quickview-tooltip']"
	            		:data-placement="quickViewContext.placement"
			            :title="quickViewContext.title"
	            		:onclick="`quickview('${product.product_id}')`">
		            	<span class="btn-text">{{ getSetting('quickviewText') }}</span>
		        	</a>
		        </div>

		        <a :href="product.href" class="product-img" :class="{ 'has-second-image': product.second_thumb }">
		        	<div style="display: none">{{ product | json }}</div>
		          	<div>
		              	<img
		              		v-if="getSetting('performanceLazyLoadImagesStatus')"
		              		:src="dummyImage"
		              		:data-src="product.thumb"
		              		:data-srcset="product.thumb2x ? `${product.thumb} 1x, ${product.thumb2x} 2x` : null"
		              		:width="imageWidth"
		              		:height="imageHeight"
		              		:alt="product.name"
		              		:title="product.name"
		              		class="img-responsive img-first lazyload" />
		              	<img
			              	v-if="!getSetting('performanceLazyLoadImagesStatus')"
			              	:src="product.thumb"
			              	:srcset="product.thumb2x ? `${product.thumb} 1x, ${product.thumb2x} 2x` : null"
			              	:width="imageWidth"
			              	:height="imageHeight"
			              	:alt="product.name"
			              	:title="product.name"
			              	class="img-responsive img-first" />

		                <img
		                	v-if="product.second_thumb && getSetting('performanceLazyLoadImagesStatus')"
		                	:src="dummyImage"
		                	:data-src="product.second_thumb"
		                	:data-srcset="product.second_thumb2x ? `${product.second_thumb} 1x, ${product.second_thumb2x} 2x` : null"
		                	:width="imageWidth"
		                	:height="imageHeight"
		                	:alt="product.name"
		                	:title="product.name"
		                	class="img-responsive img-second lazyload"/>
		                <img
		                	v-if="product.second_thumb && !getSetting('performanceLazyLoadImagesStatus')"
		                	:src="product.second_thumb"
		                	:srcset="product.second_thumb2x ? `${product.second_thumb} 1x, ${product.second_thumb2x} 2x` : null"
		                	:width="imageWidth"
		                	:height="imageHeight"
		                	:alt="product.name"
		                	:title="product.name"
		                	class="img-responsive img-second"/>
		          	</div>
		        </a>

				<div class="product-labels" v-if="product.labels">
					<span
						v-for="(label, id) in product.labels"
						class="product-label"
						:class="[`product-label-${id}`, `product-label-${label.display}`, label.type]">
						<b>{{ label.label }}</b>
					</span>
				</div>
		        <div class="countdown" :data-date="product.date_end" v-if="getSetting('countdownStatus') && product.date_end"></div>
	    	</div>

	    	<div class="caption">
	        	<div class="stats" v-if="product.stat1 || product.stat2">
					<span class="stat-1" v-if="product.stat1">
						<span class="stats-label">{{ product.stat1.label }}:</span> <span v-html="product.stat1.text"></span>
					</span>
					<span class="stat-2" v-if="product.stat2">
						<span class="stats-label">{{ product.stat2.label }}:</span> <span v-html="product.stat2.text"></span>
					</span>
	          	</div>

				<div class="name" v-if="getIn(prefix + 'NamePosition') == 'default'">
					<a :href="product.href">{{ product.name }}</a>
				</div>

	        	<div class="description">{{ product.description }}</div>

	          	<div class="price" v-if="product.price">
					<div>
						<span class="price-new" v-if="product.special">{{ product.special }}</span>
						<span :class="product.special ? 'price-old' : 'price-normal'">{{ product.price }}</span>
					</div>
	              	<span class="price-tax" v-if="product.tax">{{ textTax }}{{ product.tax }}</span>
	          	</div>

	          	<div :class="{ 'rating-hover': getIn(prefix + 'RatingPosition') == 'hover', 'no-rating': !product.rating }" class="rating">
	            	<div class="rating-stars">
	            		<span
	            			v-for="x in [1,2,3,4,5]"
	            			class="fa fa-stack">
	            			<i class="fa fa-star fa-stack-2x" v-if="product.rating >= x"></i>
	            			<i class="fa fa-star-o fa-stack-2x"></i>
	            		</span>
	            	</div>
	          	</div>

		        <div class="buttons-wrapper" v-if="getSetting('catalogCartStatus') || getSetting('catalogWishlistStatus') || getSetting('catalogCompareStatus')">
		          	<div class="button-group">
			            <div class="cart-group" v-if="getSetting('catalogCartStatus')">
			              	<div class="stepper">
			                	<input type="text" name="quantity" :value="product.minimum" :data-minimum="product.minimum" class="form-control"/>
			                	<input type="hidden" name="product_id" :value="product.product_id"/>
			                	<span>
				                	<i class="fa fa-angle-up"></i>
					                <i class="fa fa-angle-down"></i>
					            </span>
			              	</div>
			        		<a class="btn btn-cart"
			            		:data-toggle="cartContext.toggle"
			            		:data-tooltip-class="[cartContext.tooltipClass, 'cart-tooltip']"
			            		:data-placement="cartContext.placement"
					            :title="cartContext.title"
					            :onclick="`cart.add('${product.product_id}', $(this).closest('.button-group').find('input[name=\\'quantity\\']').val())`"
			            		:data-loading-text="`<span class='btn-text'>${buttonCart}</span>`">
				            	<span class="btn-text">{{ buttonCart }}</span>
				        	</a>
		            	</div>

			            <div class="wish-group" v-if="getSetting('catalogWishlistStatus') || getSetting('catalogCompareStatus')">
		        			<a class="btn btn-wishlist"
		        				v-if="getSetting('catalogWishlistStatus')"
			            		:data-toggle="wishListContext.toggle"
			            		:data-tooltip-class="[wishListContext.tooltipClass, 'wishlist-tooltip']"
			            		:data-placement="wishListContext.placement"
					            :title="wishListContext.title"
			            		:onclick="`wishlist.add('${product.product_id}')`">
				            	<span class="btn-text">{{ buttonWishlist }}</span>
				        	</a>
							<a class="btn btn-compare"
								v-if="getSetting('catalogCompareStatus')"
								:data-toggle="compareContext.toggle"
								:data-tooltip-class="[compareContext.tooltipClass, 'compare-tooltip']"
								:data-placement="compareContext.placement"
								:title="compareContext.title"
								:onclick="`compare.add('${product.product_id}')`">
								<span class="btn-text">{{ buttonCompare }}</span>
							</a>
			            </div>
		        	</div>
		    	</div>

		        <div class="extra-group" v-if="product.extra_buttons">
		            <div>
						<a class="btn btn-extra"
							v-for="(extra_button, index) in extra_buttons"
							:class="`btn-extra-${extra_button.id}`"
							:data-toggle="extra_button.ctx.toggle"
							:data-tooltip-class="[extra_button.ctx.tooltipClass, 'compare-tooltip']"
							:data-placement="extra_button.ctx.placement"
							:title="extra_button.ctx.title"
							:onclick="extra_button.action == 'quickbuy' ? `cart.add('${product.product_id}', $(this).closest('.button-group').find('input[name=\\'quantity\\']').val(), true)` : null"
							:data-loading-text="`<span class='btn-text'>${extra_button.label}</span>`"
							:href="extra_button.action == 'link' && extra_button.link.href ? extra_button.link.href : null"
							:data-product_id="extra_button.action == 'link' && extra_button.link.href ? product.product_id : null"
							:data-product_url="extra_button.action == 'link' && extra_button.link.href ? product.href : null"
							>
							<span class="btn-text">{{ buttonCompare }}</span>
						</a>
		            </div>
		        </div>
	      	</div>
	    </div>
	</div>
</template>
<style lang="sass">
	.product-label.special
		position: absolute
		top: 0
		left: 0
		display: block
		b
			background-color: red
			font-size: 15px
			box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)
</style>
<script>
	export default {
		name: 'product-card',
		props: {
			cssClass: String,
			dummyImage: String,
			context: {
				type: Object,
				default: function(){
					return {};
				}
			},
			product: {
				type: Object,
				default: function(){
					return {};
				}
			},
			moduleId: {
				type: [String, Boolean],
				default: false
			},
			buttonCart: {
				type: [String, Boolean],
				default: false
			},
			buttonWishlist: {
				type: [String, Boolean],
				default: false
			},
			buttonCompare: {
				type: [String, Boolean],
				default: false
			},
			imageWidth: {
				type: [String, Boolean],
				default: false
			},
			imageHeight: {
				type: [String, Boolean],
				default: false
			},
			textTax: {
				type: [String, Boolean],
				default: false
			}
		},
		data(){
			return {
				extra_buttons: []
			}
		},
		mounted(){
			if(this.product.extra_buttons)
				this.extra_buttons = Object.keys(this.product.extra_buttons).map((id, index) => {
					var extra_button = this.product.extra_buttons[id];
					extra_button.id = id;
					extra_button.ctx = this.getTooltipData('ExtraButton', extra_button.label, index+1);
					return extra_button;
				})
		},
		methods: {
			getSetting(key){
				if(key == 'quickviewText')
					return 'Vizualizare rapida';

				return true;

				return _.get(this.context, `settings.${key}`, null)
			},
			settings(){
				// context.module - NamePosition
				// context.settings.global - NamePosition
			},
			classes(obj){
				if(!obj)
					return false;

				var classes = [];

				Object.keys(obj).forEach(key => {
					if(obj[key] === true)
						classes.push(key);

					if(typeof obj[key] === "string")
						classes.push(obj[key]);
				})

				classes.push({
					'out-of-stock': this.product.quantity <= 0,
				    'has-countdown': this.product.date_end,
				    'has-zero-price': !this.product.price_value,
				    'has-extra-button': this.product.extra_buttons
				});

				return classes;
			},
			getIn(key){
				// /system/library/journal3/settings.php
				// public function getIn($key, $context, $default = null) {
				// 	return Arr::get($context, 'module' . $key, $this->get('global' . $key, $default));
				// }
				return _.get(this.context, `module${key}`, _.get(this.context, `settings.global${key}`, null))
				// return this.context[`module${key}`] || this.context.settings[`global${key}`] || null;
			},
			getTooltipData(key, title, appendKey){
	            // data-toggle="tooltip"
	            // data-tooltip-class="{{ module_id ? 'module-products-' ~ module_id ~ ' module-products-grid' : 'product-grid' }} quickview-tooltip"
	            // data-placement="{{ j3.settings.getIn(prefix ~ 'QuickviewTooltipPosition', _context) }}"
	            // title="{{ j3.settings.get('quickviewText') }}"

				appendKey = appendKey || '';

            	if(!this.getIn(this.prefix + key + 'Display' + appendKey) == 'icon' || !this.getIn(this.prefix + key + 'TooltipStatus'))
            		return {
            			toggle: null,
            			class: null,
            			placement: null,
            			title: null
            		};

            	return {
            		toggle: "tooltip",
            		tooltipClass: this.moduleId ? `module-products-${this.moduleId} module-products-grid` : 'product-grid',
            		placement: this.getIn(this.prefix + key + "TooltipPosition") || null,
            		title: title || _.get(this.context, `settings${key.toLowerCase()}Text`, null)
            	}
			}
		},
		computed: {
			prefix(){
				return this.context.display == "grid" ? 'ProductGrid' : 'ProductList';
			},
			quickViewContext(){
				return this.getTooltipData('Quickview');
			},
			cartContext(){
				return this.getTooltipData('Cart', this.buttonCart);
			},
			wishListContext(){
				return this.getTooltipData('Wishlist', this.buttonWishlist);
			},
			compareContext(){
				return this.getTooltipData('Compare', this.buttonCompare);
			}
		}
	}
</script>