<template>
	<div class="vue-products-slider" :class="cssClass">
		<div class="module-body" v-if="sectionDisplay == 'blocks' && items">
			<div v-for="item in items" :class="classes(item.classes)">
				<h3 class="title module-title" v-if="item.title">{{ item.title }}</h3>
				<div v-if="context.carousel" class="swiper" :data-items-per-row='context.itemsPerRow | json' :data-options='context.carouselOptions | json'>
					<div class="swiper-container">
						<div class="swiper-wrapper" :class="`product-${context.display}`">
							<product-card
								v-for="(product, id) in item.products"
								:key="id"
								:moduleId="moduleId"
								:dummyImage="dummyImage"
								:imageWidth="imageWidth"
								:imageHeight="imageHeight"
								:cssClass="cssClass"
								:context="context"
								:product="product"
								:buttonCart="buttonCart"
								:buttonWishlist="buttonWishlist"
								:buttonCompare="buttonCompare"
								:textTax="textTax" />
						</div>
					</div>
					<div class="swiper-buttons">
						<div class="swiper-button-prev"></div>
						<div class="swiper-button-next"></div>
					</div>
					<div class="swiper-pagination"></div>
				</div>
				<div :class="`product-${context.display}`" v-if="!context.carousel">
					<product-card
						v-for="(product, id) in item.products"
						:key="id"
						:moduleId="moduleId"
						:dummyImage="dummyImage"
						:imageWidth="imageWidth"
						:imageHeight="imageHeight"
						:cssClass="cssClass"
						:context="context"
						:product="product"
						:buttonCart="buttonCart"
						:buttonWishlist="buttonWishlist"
						:buttonCompare="buttonCompare"
						:textTax="textTax" />
				</div>
			</div>
		</div>

		<div class="module-body" v-if="sectionDisplay == 'tabs'">
			<div class="tab-container">
				<ul class="nav nav-tabs">
					<li v-for="(item, index) in items" :class="classes(item.classes)">
						<h1 class="htitle" v-if="item.title">
							<a :href="item.link.href" v-if="item.link.href">{{ item.title }}</a>
							<a :href="`#${id}-tab-${index}`" v-if="!item.link.href" data-toggle="tab">{{ item.title }}</a>
						</h1>
					</li>
				</ul>
				<div class="tab-content">
					<div v-for="(item, index) in items" :class="classes(item.classes)" :id="`${id}-tab-${index}`">
						<div v-if="context.carousel" class="swiper" :data-items-per-row='context.itemsPerRow | json' :data-options='context.carouselOptions | json'>
							<div class="swiper-container">
								<div class="swiper-wrapper" :class="`product-${context.display}`">
									<product-card
										v-for="(product, id) in item.products"
										:key="id"
										:moduleId="moduleId"
										:dummyImage="dummyImage"
										:imageWidth="imageWidth"
										:imageHeight="imageHeight"
										:cssClass="cssClass"
										:context="context"
										:product="product"
										:buttonCart="buttonCart"
										:buttonWishlist="buttonWishlist"
										:buttonCompare="buttonCompare"
										:textTax="textTax" />
								</div>
							</div>
							<div class="swiper-buttons">
								<div class="swiper-button-prev"></div>
								<div class="swiper-button-next"></div>
							</div>
							<div class="swiper-pagination"></div>
						</div>
						<div v-if="!context.carousel" :class="`product-${context.display}`">
							<product-card
								v-for="(product, id) in item.products"
								:key="id"
								:moduleId="moduleId"
								:dummyImage="dummyImage"
								:imageWidth="imageWidth"
								:imageHeight="imageHeight"
								:cssClass="cssClass"
								:context="context"
								:product="product"
								:buttonCart="buttonCart"
								:buttonWishlist="buttonWishlist"
								:buttonCompare="buttonCompare"
								:textTax="textTax" />
						</div>
		            </div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import ProductCard from '@/ProductCard.vue'
	export default {
		name: 'vue-products-slider',
		components: { ProductCard },
		data(){
			return {
				cssClass: '',
				sectionDisplay: '',
				items: null,
				id: null,
				test: 'asd',
				context: null,
				dummyImage: '',
				moduleId: null,
				buttonCart: '',
				buttonWishlist: '',
				buttonCompare: '',
				imageWidth: '',
				imageHeight: '',
				textTax: '',
				carouselData: false
			}
		},
		beforeMount(){
			this.cssClass = this.$el.getAttribute('data-vue-classes');
			this.sectionDisplay = this.$el.getAttribute('data-vue-section-display');
			this.id = this.$el.getAttribute('data-vue-id');
			this.dummyImage = this.$el.getAttribute('data-vue-dummy-image');
			this.moduleId = this.$el.getAttribute('data-vue-module-id');
			this.textTax = this.$el.getAttribute('data-vue-text-tax');
			this.buttonCart = this.$el.getAttribute('data-vue-button-cart');
			this.buttonWishlist = this.$el.getAttribute('data-vue-button-wishlist');
			this.imageWidth = this.$el.getAttribute('data-vue-image-width');
			this.imageHeight = this.$el.getAttribute('data-vue-image-height');
			this.buttonCompare = this.$el.getAttribute('data-vue-button-compare');
			this.items = JSON.parse(this.$el.getAttribute('data-vue-items'));
			this.context = JSON.parse(this.$el.getAttribute('data-vue-context'));
			// console.log(this.$el.getAttribute('data-vue-context'))
		},
		mounted(){
			window.setTimeout(() => journal_enable_stepper(), 1000)

			if(this.context.carousel)
				this.setCarouselData()
		},
		methods: {
			setCarouselData(){
				var key = 'c0';
				var that = this.$el.querySelector('.swiper');
				var $that = $(that);

				if(this.$el.closest('#content')){
					key = 'c' + window['Journal']['columnsCount'];
				}
				else {
					if(this.$el.closest('#column-left') || this.$el.closest('#column-right')){
						key = 'sc';
					}
				}

				var itemsPerRow = { 0: { items: 1, spacing: 0 } };

				if(this.context.itemsPerRow && this.context.itemsPerRow[key])
					itemsPerRow = this.context.itemsPerRow[key];

				var breakpoints = {};

				Object.keys(itemsPerRow).forEach(key => {
					breakpoints[key] = {
						slidesPerView: parseInt(itemsPerRow[key].items, 10),
						slidesPerGroup: parseInt(itemsPerRow[key].items, 10),
						spaceBetween: parseInt(itemsPerRow[key].spacing, 10)
					}
				})

				var options = $.extend({
					init: false,
					slidesPerView: parseInt(itemsPerRow[0].items, 10),
					slidesPerGroup: parseInt(itemsPerRow[0].items, 10),
					spaceBetween: parseInt(itemsPerRow[0].spacing, 10),
					breakpoints: breakpoints,
					observer: true,
					observeParents: true,
					paginationClickable: true,
					preventClicks: false,
					preventClicksPropagation: false,
					simulateTouch: true,
					watchSlidesProgress: true,
					watchSlidesVisibility: true,
					navigation: {
						nextEl: $that.find('.swiper-button-next'),
						prevEl: $that.find('.swiper-button-prev')
					},
					pagination: {
						el: $that.find('.swiper-pagination'),
						type: 'bullets',
						clickable: true
					},
					scrollbar: $that.find('.swiper-scrollbar'),
					scrollbarHide: false,
					scrollbarDraggable: true
				}, $that.data('options'));

				var swiper = new Swiper($('.swiper-container', that), options);
				
				function checkPages() {
					if ($('.product-image').hasClass('direction-vertical') && $that.hasClass('additional-images')) {
						var height = Journal['isPopup'] ? Journal['quickviewPageStyleAdditionalImagesHeightAdjustment'] : Journal['productPageStyleAdditionalImagesHeightAdjustment'];

						var interval = setInterval(function () {
							var imageHeight = $('.main-image .swiper-slide-visible img').outerHeight();

							if (imageHeight) {
								$that.css('height', imageHeight + (parseInt(height, 10) || 0));
								swiper.update();
								clearInterval(interval);
								$('.product-image').addClass('additional-images-loaded');
							}
						}, 1000);
					}

					if (swiper.isBeginning && swiper.isEnd) {
						$that.removeClass('swiper-has-pages');
					} else {
						$that.addClass('swiper-has-pages');
					}
				}

				swiper.on('init', checkPages);
				swiper.on('resize', checkPages);

				swiper.init()

				if (options.autoplay) {
					// pause on hover
					if (options.pauseOnHover) {
						$('.swiper-container', that).hover(function () {
							swiper.autoplay.stop();
						}, function () {
							swiper.autoplay.start();
						});
					}

					// stop autoplay for elements not in viewport
					swiper.on('observerUpdate', function () {
						var visible = $(swiper.$el).is(':visible');
						var running = swiper.autoplay.running;

						if (visible && !running) {
							swiper.autoplay.start();
						}

						if (!visible && running) {
							swiper.autoplay.stop();
						}
					});
				}

				$that.data('swiper', swiper);
			},
			classes(obj){
				if(!obj)
					return false;

				var classes = [];

				Object.keys(obj).forEach(key => {
					if(obj[key] === true)
						classes.push(key);

					if(typeof obj[key] === "string")
						classes.push(obj[key]);
				})

				return classes;
			},
			isNumber(n){
				return !isNaN(parseFloat(n)) && isFinite(n);
			}
		}
	}
</script>

<style lang="sass">
.vue-products-slider
	box-sizing: border-box
</style>
