import _ from 'lodash'
import Vue from 'vue'
import AppComponent from './App.vue'
import ContextHelper from './ContextHelper.vue'
var App = Vue.extend(AppComponent);

Vue.config.productionTip = false
Vue.component('context-helper', ContextHelper)
function init(){
	var elements = document.querySelectorAll('.vue-products-slider');

	for(var i = 0, length1 = elements.length; i < length1; i++){
		new App({
			el: elements[i]
		})
	}
}

if (!Element.prototype.matches) {
  Element.prototype.matches = Element.prototype.msMatchesSelector || 
                              Element.prototype.webkitMatchesSelector;
}

if (!Element.prototype.closest) {
  Element.prototype.closest = function(s) {
    var el = this;

    do {
      if (el.matches(s)) return el;
      el = el.parentElement || el.parentNode;
    } while (el !== null && el.nodeType === 1);
    return null;
  };
}

if (document.readyState === "complete" || document.readyState === "loaded" || document.readyState === "interactive") {
	init();
}
else {
	document.addEventListener("DOMContentLoaded", init);
}